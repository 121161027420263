import React from "react";
import { NavLink, Link as RouterLink } from "react-router-dom";
import logo from "../../assets/necklace.jpeg";
import Drawer from "@material-ui/core/Drawer";
import "../../styles/mediaStyles.css";
import styled from "@emotion/styled";

const MainContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
  background-color: rgb(17, 6, 47);
  color: white;
  height: 80px;
`;

const NavItems = styled.div`
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;
const StyledRouterLinkWhite = styled(RouterLink)`
  color: white;
  text-decoration: none;
  &:hover,
  &:active {
    color: white;
    text-decoration: none;
  }
`;
const StyledRouterLinkBlack = styled(RouterLink)`
  color: white;
  text-decoration: none;
  &:hover,
  &:active {
    color: black;
    text-decoration: none;
  }
`;
const LogoText = styled.div`
  font-size: 35px;
  width: 150px;
`;
const HomeLink = styled(StyledRouterLinkBlack)`
  display: grid;
  grid-template-columns: 60px 1fr;
  background-color: white;
  height: 70px;
  width: fit-content;
  color: black;
  border-radius: 9px;
  grid-gap: 10px;
  padding-left: 10px;
  align-items: center;
`;

const Link = styled(StyledRouterLinkWhite)``;
const LogoImg = styled.img`
  width: 60px;
`;
const NavMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Navbar = () => {
  return (
    <MainContainer>
      <NavItems>
        <HomeLink to="/">
          <LogoImg src={logo} />
          <LogoText>Precious Pearls</LogoText>
        </HomeLink>
        <NavMenu>
          <Link to="/about">About us</Link>
          <Link to="/products">All Products</Link>
          <Link to="/contact">Contact</Link>
        </NavMenu>
      </NavItems>
    </MainContainer>
  );
};

export default Navbar;
