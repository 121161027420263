import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./components/home";
import Home404 from "./components/home/Home404";
import Navbar from "./components/shared/Navbar";
import ScrollToTop from "./components/shared/ScrollToTop";
import "./styles/mediaStyles.css";
import "./styles/common.scss";

class App extends Component {
  state = {
    posts: []
  };
  componentDidMount() {
  }

  render() {
    return (
      <div
        style={{
          fontSize: "17px",
          fontFamily: "Barlow",
          lineHeight: 1,
          color: "#11062F"
        }}
      >
        <Navbar {...this.props} />

        <ScrollToTop />

        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/about" component={About} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/howitworks" component={HowItWorks} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/test" component={Privacy} />
          <Route
            path="/blog/"
            render={props => <Blog {...props} posts={this.state.posts} />}
          />
          <Route
            path="/blogs/"
            render={props => {
              return <BlogItem {...props} posts={this.state.posts} />;
            }}
          />
          <Route exact path="/lp/a" component={LandingPage} />
          <Route exact path="/lp/b" component={LandingPage} />
          <Route exact path="/case-study/:customer" component={CaseStudy} /> */}
          <Route exact path="/404" component={Home404} />
          {/* when none of the above match, <NoMatch> will be rendered */}
          <Route component={Home404} />
        </Switch>
        {/* <Footer {...this.props} /> */}
      </div>
    );
  }
}

export default withRouter(App);
